module.exports = {
  1: { api: 1, human: 'Schleswig-Holstein' },
  2: { api: 2, human: 'Hamburg' },
  3: { api: 3, human: 'Niedersachsen' },
  4: { api: 4, human: 'Bremen' },
  5: { api: 5, human: 'Nordrhein-Westfalen' },
  6: { api: 6, human: 'Hessen' },
  7: { api: 7, human: 'Rheinland-Pfalz' },
  8: { api: 8, human: 'Baden-Württemberg' },
  9: { api: 9, human: 'Bayern' },
  10: { api: 10, human: 'Saarland' },
  11: { api: 11, human: 'Berlin' },
  12: { api: 12, human: 'Brandenburg' },
  13: { api: 13, human: 'Mecklenburg-Vorpommern' },
  14: { api: 14, human: 'Sachsen' },
  15: { api: 15, human: 'Sachsen-Anhalt' },
  16: { api: 16, human: 'Thüringen' }
}
