<template>
  <div>
    <ca-header
      heading="HSS Data Edit"
      :column-layout="true"
    />
    <div class="container-fluid px-4 pt-4">
      <div class="card">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-12">
              <basic-select
                id="status"
                v-model="service"
                :options="tableOptions"
                :margin="false"
                :disabled="hasChanges"
                @input="select"
              />
            </div>
          </div>
        </div>
        <div class="card-body pt-1">
          <template v-if="loaded">
            <EditableGrid
              :data="data"
              :column-defs="columnDefs"
              :show-raw-data="false"
              :collection="collection"
              :editable="$can('editCalculationData')"
              @row-changed="onRowChanged"
              @cell-updated="onCellUpdated"
              @limit-changed="onLimitChanged"
              @page-changed="onPageChanged"
            />
            <div class="row">
              <div class="col-12 mt-2">
                <span class="float-right text-sm text-muted">
                  {{ data.length }} Einträge
                </span>
              </div>
              <div class="col-12 mt-2">
                <button
                  class="btn btn-primary btn-sm"
                  @click="add"
                >
                  <i class="fas fa-plus mr-2" />Eintrag hinzufügen
                </button>
                <button
                  class="btn btn-primary btn-sm ml-2"
                  :disabled="data.length === 0 || currentRow === null"
                  @click="remove"
                >
                  <i class="fas fa-minus  mr-2" />Ausgewählten Eintrag löschen
                </button>
                <button
                  class="btn btn-success btn-sm text-white ml-2 float-right"
                  :disabled="!hasChanges"
                  @click="save"
                >
                  <i class="fas fa-save mr-2" />Änderungen speichern
                </button>
                <button
                  class="btn btn-danger btn-sm text-white ml-2 float-right"
                  :disabled="!hasChanges"
                  @click="discard"
                >
                  <i class="fas fa-trash mr-2" />Änderungen verwerfen
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import EditableGrid from '@/components/EditableGrid.vue'
import feathers from '@/api'
import cloneDeep from 'lodash/cloneDeep'
import enumSelbstbehaltTk from '@/resources/enums/selbstbehaltTK'
import enumSelbstbehaltVk from '@/resources/enums/selbstbehaltVK'
import enumBundeslaender from '@/resources/enums/bundeslaender'
import enumFahrerkreise from '@/resources/enums/fahrerkreise'
import enumKennzeichenArten from '@/resources/enums/kennzeichenArten'
import enumLaender from '@/resources/enums/laender'
import enumNutzungen from '@/resources/enums/nutzungen'
import enumSegmente from '@/resources/enums/segmente'
import enumWkz from '@/resources/enums/wkz.js'
import enumZahlarten from '@/resources/enums/zahlarten.js'
import enumZahlweisen from '@/resources/enums/zahlweisen.js'

export default {
  name: 'HSSDataEdit',
  components: {
    EditableGrid,
    CaHeader,
    BasicSelect
  },
  data: () => ({
    data: [],
    dataCurrent: [],
    columnDefs: [],
    currentRow: 0,
    keys: null,
    service: '',
    loaded: false,
    hasChanges: false,
    tableOptions: [
      { label: 'Grundtarif Kasko', value: 'grundtarif-kasko' },
      { label: 'Grundtarif KH', value: 'grundtarif-kh' },
      // { label: 'KD Alter', value: 'kd-alter' },
      { label: 'KD Zahlweise', value: 'kd-zahlweise' },
      // { label: 'KD Zahlart', value: 'kd-zahlart' },
      { label: 'FZ Abstellplatz PLZ', value: 'kd-plz' },
      { label: 'FZ Abstellplatz Bundesland', value: 'kd-bundesland' },
      // { label: 'KD Land', value: 'kd-land' },
      { label: 'FZ Erstzulassung', value: 'fz-erstzulassung' },
      { label: 'FZ Motorstärke', value: 'fz-motorstaerke' },
      { label: 'FZ Laufleistung', value: 'fz-laufleistung' },
      { label: 'FZ Art', value: 'fz-art' },
      { label: 'FZ Kennzeichenart', value: 'fz-kennzeichen-art' },
      { label: 'FZ Saisondauer', value: 'fz-saisondauer' },
      { label: 'FZ Nutzung', value: 'fz-nutzung' },
      { label: 'FZ Abstellplatz', value: 'fz-abstellplatz' },
      { label: 'FZ Umbau', value: 'fz-umbau' },
      { label: 'FZ in Restaurierung', value: 'fz-restaurierung' },
      { label: 'Fahrerkreis', value: 'fahrerkreis' },
      { label: 'Alter Fahrerkreis', value: 'alter-fahrerkreis' },
      { label: 'FZ Versicherungswert', value: 'versicherungswert' },
      { label: 'MR Deckung', value: 'mr-deckung' }

    ],
    limit: 15,
    page: 1
  }),
  created () {
    this.service = this.$route.query.service ? this.$route.query.service : 'grundtarif-kasko'
    this.getData()
  },
  methods: {
    add: function () {
      const obj = {}
      this.keys.forEach(key => {
        obj[key] = null
      })
      this.data.push(obj)
    },
    remove: function () {
      this.hasChanges = true
      this.data.splice(this.currentRow, 1)
    },
    select: function () {
      this.page = 1
      this.loaded = false
      this.getData()
    },
    getData: async function () {
      this.loaded = false
      // Push get parameter
      const url = new URL(window.location)
      url.searchParams.set('service', this.service)
      window.history.pushState({}, '', url)

      // Get data from DB
      this.collection = await feathers.service('hsdata-' + this.service).find({ query: { $limit: this.limit, $skip: (this.page - 1) * this.limit } })
      this.data = this.collection.data
      this.dataCurrent = cloneDeep(this.data)

      // Get keys and filter Mongo keys
      this.keys = Object.keys(this.data[0]).filter(key => !key.startsWith('_') && key !== 'createdAt' && key !== 'updatedAt' && key !== 'anfrage' && key !== 'ablehnung')
      // Push each key to table schema
      this.columnDefs = []
      this.currentRow = null

      let selectOptions = []

      // Converts an international number to one with german separators
      const numericFormatter = event => {
        if (!event || event.value === null || event.value === undefined) return ''
        if (event.reverse) {
          // Convert from display to raw
          return Number(event.value.replace(/,/g, '.'))
        }
        return event.value.toString().replace(/\./g, ',')
      }

      this.keys.forEach(key => {
        let formatter = null
        let type
        switch (key) {
        case 'anfrage':
        case 'ablehnung':
          type = 'select'
          selectOptions = [{ value: false, text: 'Nein' }, { value: true, text: 'Ja' }]
          break
        case 'kh':
        case 'vk':
        case 'tk':
        case 'von':
        case 'bis':
        case 'praemie':
          type = 'numeric'
          formatter = numericFormatter
          break
        case 'selbstbehalt':
          type = 'select'
          selectOptions = [...mapEnums(enumSelbstbehaltTk), ...mapEnums(enumSelbstbehaltVk)]
          break
        case 'type':
          type = 'select'
          selectOptions = [{ value: 'VK', text: 'VK' }, { value: 'TK', text: 'TK' }]
          break
        case 'bundesland':
          type = 'select'
          selectOptions = mapEnums(enumBundeslaender)
          break
        case 'fahrerkreis':
          type = 'select'
          selectOptions = mapEnums(enumFahrerkreise)
          break
        case 'kennzeichenArt':
          type = 'select'
          selectOptions = mapEnums(enumKennzeichenArten)
          break
        case 'land':
          type = 'select'
          selectOptions = mapEnums(enumLaender)
          break
        case 'nutzung':
          type = 'select'
          selectOptions = mapEnums(enumNutzungen)
          break
        case 'segment':
          type = 'select'
          selectOptions = mapEnums(enumSegmente)
          break
        case 'wkz':
          type = 'select'
          selectOptions = mapEnums(enumWkz)
          break
        case 'zahlart':
          type = 'select'
          selectOptions = mapEnums(enumZahlarten)
          break
        case 'zahlweise':
          type = 'select'
          selectOptions = mapEnums(enumZahlweisen)
          break
        default:
          type = 'string'
        }

        this.columnDefs.push({
          sortable: true,
          filter: false,
          field: key,
          headerName: getUeberschrift(key),
          editable: true,
          type: type,
          selectOptions: selectOptions,
          formatter: formatter
        })
      })
      replaceBooleans()
      this.loaded = true
    },
    discard: function () {
      this.data = cloneDeep(this.dataCurrent)
      replaceBooleans()
      this.hasChanges = false
    },
    save: async function () {
      // Check if entries are null
      for (const entry of this.data) {
        if (Object.values(entry).some(x => x === null)) return
      }

      // TODO: Not working correctly: Arrays contain unchanged arrays
      const removedEntries = this.dataCurrent.filter(x => !this.data.includes(x))
      const addedEntries = this.data.filter(x => !this.dataCurrent.includes(x))

      for (const entry of removedEntries) {
        await feathers.service('hsdata-' + this.service).remove(entry._id)
      }

      for (const entry of addedEntries) {
        await feathers.service('hsdata-' + this.service).create(entry)
      }
      this.hasChanges = false
    },
    onRowChanged: function (row) {
      this.currentRow = row
    },
    onCellUpdated: function () {
      replaceBooleans()
      this.hasChanges = true
    },
    onLimitChanged: function (limit) {
      this.limit = limit
      this.page = 1
      this.getData()
    },
    onPageChanged: function (page) {
      this.page = page
      this.getData()
    }
  }
}

function getUeberschrift (key) {
  const ueberschriften = {
    tk: 'TK',
    vk: 'VK',
    kh: 'KH',
    type: 'Typ',
    selbstbehaltVk: 'Selbstbehalt VK',
    selbstbehaltTk: 'Selbstbehalt TK',
    praemie: 'Prämie',
    wkz: 'WKZ',
    plz: 'PLZ',
    kennungBundesland: 'Kennung Bundesland',
    kennzeichenArt: 'Kennzeichenart'
  }

  if (ueberschriften[key]) {
    return ueberschriften[key]
  } else {
    return key.charAt(0).toUpperCase() + key.slice(1)
  }
}

// Please don't look at this. This is way worse then a bad hack, but it fixes the problem
function replaceBooleans () {
  setTimeout(function () {
    document.querySelectorAll('.cell-content').forEach(function (cell) {
      if (cell.innerHTML.includes('true')) {
        cell.classList.add('bool-true')
      } else if (cell.innerHTML.includes('false')) {
        cell.classList.add('bool-false')
      }
    })
  }, 1)
}

function mapEnums (enums) {
  return Object.values(enums).map(x => ({
    value: x.api,
    text: x.api + ' | ' + (x.human ? x.human : x.von + ' - ' + x.bis)
  }))
}
</script>

<style lang="scss">
.bool-true span, .bool-false span {
  display: none;
}

.bool-true:after {
  content:'Ja';
}

.bool-false:after {
  content:'Nein';
}
</style>
