<template>
  <div>
    <vue-editable-grid
      id="mygrid"
      ref="grid"
      class="my-grid-class table table-striped"
      :column-defs="columnDefs"
      :row-data="data"
      row-data-key="id"
      :editable="editable"
      @row-selected="onSelect"
      @cell-updated="onUpdate"
    >
      <template v-slot:header />
      <template v-slot:header-r />
    </vue-editable-grid>
    <div class="card-body">
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <span
            v-if="collection.total"
            class="small muded"
          >{{ collection.total }} Einträge auf {{ pages }} Seiten</span>
        </div>
        <div class="col-auto">
          <b-pagination
            v-model="page"
            :total-rows="collection.total"
            :per-page="collection.limit"
            class="mb-0 pagination"
            @change="$emit('page-changed', $event)"
          >
            <template slot="first-text">
              <i class="far fa-angle-double-left" />
            </template>
            <template slot="prev-text">
              <i class="far fa-angle-left" />
            </template>
            <template slot="next-text">
              <i class="far fa-angle-right" />
            </template>
            <template slot="last-text">
              <i class="far fa-angle-double-right" />
            </template>
            <template slot="ellipsis-text">
              <i class="far fa-ellipsis-h" />
            </template>
          </b-pagination>
        </div>
        <div class="col">
          <div class="d-flex justify-content-end align-items-center">
            <i
              v-if="loading"
              class="fas fa-circle-notch fa-spin mr-2"
            />
            Einträge pro Seite:
            <basic-select
              v-model="limit"
              class="ml-2"
              :margin="false"
              @input="$emit('limit-changed', limit)"
            >
              <option :value="15">
                15
              </option>
              <option :value="20">
                20
              </option>
              <option :value="30">
                30
              </option>
              <option :value="40">
                40
              </option>
              <option :value="50">
                50
              </option>
            </basic-select>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showRawData">
      <pre>
      {{ data }}
    </pre>
    </template>
  </div>
</template>

<script>
import VueEditableGrid from 'vue-editable-grid'
import 'vue-editable-grid/dist/VueEditableGrid.css'
import BasicSelect from './BaseComponents/BasicSelect.vue'

export default {
  components: {
    VueEditableGrid,
    BasicSelect
  },
  props: {
    data: {
      type: Array,
      default: null
    },
    columnDefs: {
      type: Array,
      default: null
    },
    showRawData: {
      type: Boolean,
      default: false
    },
    collection: {
      type: Object,
      default: () => { return { data: [] } }
    },
    loading: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      currentRow: null,
      page: 1
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.collection.total / this.collection.limit)
    }
  },
  watch: {
    collection: {
      immediate: true,
      handler () {
        this.limit = this.collection.limit
        this.page = (this.collection.skip / this.collection.limit) + 1
      }
    }
  },
  methods: {
    onSelect: function (message, event) {
      if (message.rowIndex !== this.currentRow && typeof message.rowIndex !== 'undefined') {
        this.currentRow = message.rowIndex
        this.$emit('row-changed', message.rowIndex)
      }
    },
    onUpdate: function (message, event) {
      this.$emit('cell-updated')
    }
  }
}
</script>

<style lang="scss">
.vue-editable-grid th {
  padding: 10px!important;
}

.vue-editable-grid .grid-table.filters {
  grid-template-rows: auto;
}

.vue-editable-grid thead {
  box-shadow: none;
  -webkit-box-shadow: none;
  height: fit-content;
}

.vue-editable-grid thead th {
  border-top:none;
  font-weight: bold;
}

.vue-editable-grid thead tr:nth-child(2) {
  display: none;
}

.vue-editable-grid tbody {
  overflow: auto;
}

.vue-editable-grid tr:hover {
  cursor: pointer;
}

.vue-editable-grid tbody tr:hover {
   color: #545454;
   background-color: rgba(0, 0, 0, 0.075);
 }

.vue-editable-grid .resize-handle, .vue-editable-grid .grid-tools {
  display: none;
}
</style>
